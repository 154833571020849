import React, { Fragment } from "react"

import "../styles/projectHan.scss"

import Contact from "../components/New-Contact-Form/new-contact-form"
import TestimonialBelt from "../components/Testimonial-Belt/testimonial-belt"

import { hanUniversityProject } from "../constants/new-ghost-projects"

import theHanStudents from "../images/projects/the-han-students.jpg"

const ProjectHan = () => {
  return (
    <Fragment>
      <div className="project-han-title">
        {/* <h1>HAN Open Science Platform</h1> */}
      </div>
      <div className="project-han-description">
        <p>{hanUniversityProject.longDescription}</p>
      </div>
      <div className="project-han-main-container">
        <img src={theHanStudents} alt="The Han Open Science Project" />
      </div>
      <TestimonialBelt />
      <Contact />
    </Fragment>
  )
}

export default ProjectHan
